import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import AddStock from "../components/stock/AddStock";
import AddNFXml from "components/stock/AddNFXml";
import StockList from "../components/stock/StockList"
import { Authorized } from "utils/AuthUtil";

class Stock extends Component {
    constructor() {
        super();
        this.state = {
            listing: true,
        };
    }

    render() {
        const { listing } = this.state;
        return (
            <div>
                <Container>
                    <StockList />
                                       
                        <Link  className="btn btn-primary position-sticky fixed-button" to="/manage/stocks/edit">
                            Importar XML
                        </Link>{' '}
                        {/* <Link  className="btn btn-success position-sticky fixed-button" to="/manage/stocks/edit?stock=OUT">
                            Saída de NF em XML
                        </Link>{' '} */}
                        <Link  className="btn btn-success position-sticky fixed-button" to="/manage/stocks/editxml?stock=OUT">
                            Gerar Saída de NF
                        </Link>{' '}
                        <Authorized>
                            <Link  className="btn btn-danger position-sticky fixed-button" to="/manage/stocks/editxml?stock=FIX">
                                Retificar Estoque
                            </Link>
                        </Authorized>
                   
                </Container>
            </div>
        );
    }
}

class CreateStock extends Component {
    constructor() {
        super();
    }
    render() {

        return (
            <div>
                <Container>
                    <AddNFXml />
                </Container>
            </div>
        );
    }
}

class EditStock extends Component {
    constructor() {
        super();
    }
    render() {

        return (
            <div>
                <Container>
                    <AddStock />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const ListSt = connect(mapStateToProps, {
})(withRouter(Stock))

const CreateSt = connect(mapStateToProps, {
})(withRouter(CreateStock))

const EditSt = connect(mapStateToProps, {
})(withRouter(EditStock))

export { ListSt, CreateSt, EditSt }