import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getFpFiles } from "./FpFileActions";
import { IMaskInput } from "react-imask";
import FpFile from "./FpFile"
import { setMask, cnpjMask, cnpjValidation, onlyNumber } from "../../utils/mask"
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import { formatDate, formatDateAndHours } from "../../utils/Data";
import { header } from "../../utils/Datagrid";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { getNCMGenerals } from "components/ncmgeneral/NCMGeneralActions"
import { Search } from 'react-bootstrap-icons';
import Loader from "components/utils/Loader"
import { DateTime } from 'luxon';

import {
  Card,
  Form,
  Row,
  Col,
  Button
} from "react-bootstrap";

class FpFileList extends Component {
  constructor(props) {
    super(props);
    this.dt = React.createRef();
    this.state = {
      options: {
        ncm_general: "",
        with_stock: 'True',
        agg: true
      },
      filters: {
        company_section: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        updated_at: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        file_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      loading: false
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.props.getFpFiles(this.state.options).then(() => {
    })
      .catch((error) => { })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  cols = [
    { field: 'company_section', header: 'Seção da Gestora' },
    { field: 'created_at', header: 'Data de Criação' },
    { field: 'updated_at', header: 'Data de Atualização' },
    { field: 'file_name', header: 'Nome do Arquivo' },
  ];

  handleSubmit = () => {
    this.setState({ loading: true })
    this.props.getFpFiles(this.state.options).then(() => {
      this.setState({ loading: false })
    })
  }

  exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));

  dateBodyTemplate = (rowData) => {
    return formatDate(rowData.created_at);
  };

  alocBodyTemplate = (rowData) => {
    return <FpFile key={rowData.id} fpFile={rowData} />
  }

  onChange = e => {
    var options = { ...this.state.options }
    options[e.target.name] = e.target.value
    this.setState({ options, loading: true })
    
    this.props.getFpFiles(options).then(() => {
      this.setState({ loading: false })
    })
  };

  render() {
    const { fpFiles } = this.props.fpFilesReduc;
    let items = []

    items = fpFiles.map(fpFile => {
      var item = { ...fpFile }
      item.created_at = formatDateAndHours(item.created_at)
      item.updated_at = formatDateAndHours(item.updated_at)
      return item
    })

    const { ncmgenerals } = this.props.ncmgeneralsReduc

    let ncmgenerals_options = ncmgenerals.map(c => {
      return <option key={c.id} value={c.id}>{c.name + ' ' + c.code}</option>
    })

    return (
      <>
        <Loader loading={this.state.loading} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Cadastro de Arquivos para Envio</Card.Title>
                <p className="card-category">
                  Arquivos Gerados
                </p>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <div className="card">
                  <Tooltip target=".export-buttons>button" position="bottom" />
                  <DataTable ref={this.dt} value={items} header={(<div>
                    {/* <Row className="mb-3"></Row> */}
                    <Row>
                      {/* <Col md="10">
                      <Row >                
                        <Form.Group as={Col} md="4">
                          <Form.Label className="font-weight-bold" >NCM</Form.Label>
                          <Form.Control
                            as="select" aria-label="NCM" name="ncm_general" onChange={this.onChange} value={this.state.options.ncm_general}>
                            <option value="">--- SELECIONE ---</option>
                            {ncmgenerals_options}
                          </Form.Control>
                          <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        </Form.Group> 
                        <Form.Group as={Col} md="4">
                          <Form.Label className="font-weight-bold" >Em Estoque</Form.Label>
                          <Form.Control
                            as="select" aria-label="with_stock" name="with_stock" onChange={this.onChange} value={this.state.options.with_stock}>
                            {[<option key={1} value={'True'}>Sim</option>,
                              <option key={2} value={'False'}>Não</option>]}
                          </Form.Control>
                          <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        </Form.Group> 
                        <Form.Group as={Col} md="4">
                          <Form.Label className="font-weight-bold" >Visualização</Form.Label>
                          <Form.Control
                            as="select" aria-label="agg" name="agg" onChange={this.onChangeVisualizacao} value={this.state.options.agg}>
                            {[<option key={1} value={true}>Agregada</option>,
                              <option key={2} value={false}>Analítica</option>]}
                          </Form.Control>
                          <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </Col> */}
                      <Form.Group as={Col} md="4" controlId="nf">
                        <Form.Label className="font-weight-bold">Data de emissão inicial</Form.Label>
                        <Form.Control required type="date" name="start_at"
                          value={DateTime.fromISO(this.state.options.start_at, { setZone: true }).toFormat('yyyy-MM-dd')}
                          onChange={this.onChange} maxLength={200} />
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Campo obrigatório.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} md="4" controlId="nf">
                        <Form.Label className="font-weight-bold">Data de emissão final</Form.Label>
                        <Form.Control required type="date" name="end_at"
                          value={DateTime.fromISO(this.state.options.end_at, { setZone: true }).toFormat('yyyy-MM-dd')}
                          onChange={this.onChange} maxLength={200} />
                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Campo obrigatório.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Col >
                        {header(fpFiles, "Arquivo", this.dt, this.exportColumns)}
                      </Col>
                    </Row>
                  </div>)}
                    tableStyle={{ minWidth: '50rem' }}
                    paginator rows={5}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    // filterDisplay="row"
                    filters={this.state.filters}
                    emptyMessage="Não há arquivos gerados"

                  >
                    {this.cols.map((col, index) => (
                      <Column key={index} filter sortable field={col.field} header={col.header} />
                    ))}
                    {<Column body={this.alocBodyTemplate}></Column>}
                  </DataTable>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

FpFileList.propTypes = {
  getFpFiles: PropTypes.func.isRequired,
  getNCMGenerals: PropTypes.func.isRequired,
  fpFilesReduc: PropTypes.object.isRequired,
  ncmgeneralsReduc: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  fpFilesReduc: state.fpFilesReduc,
  ncmgeneralsReduc: state.ncmgeneralsReduc,
});

export default connect(mapStateToProps, {
  getFpFiles, getNCMGenerals
})(withRouter(FpFileList));