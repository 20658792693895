import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getNCMGenerals, getNCMGeneral } from '../ncmgeneral/NCMGeneralActions';
import { AutoComplete } from 'primereact/autocomplete';
import { Form } from 'react-bootstrap';

class AutoCompleteNCM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredNCMGenerals: [],
            selectedNCMGeneral: null,
            inputValue: '',
        };
    }

    search = (event) => {
        const query = event.query.toLowerCase();
        this.props.getNCMGenerals({ query: query }).then(() => {
            const filteredNCMGenerals = this.props.ncmgeneralsReduc.ncmgenerals.map((ncm) => {
                return {
                    id: ncm.id,
                    code: ncm.code,
                    name: ncm.name,
                    code_name: ncm.code + ' ' + ncm?.name?.toUpperCase(),
                };
            });
            this.setState({ filteredNCMGenerals, inputValue: event.query });
        });
    }

    componentDidMount() {
        if (this.props.value) {
            this.setNCMGeneral(this.props.value);
        }
    }

    setNCMGeneral = (value) => {
        this.props.getNCMGeneral(value).then(() => {
            const selectedNCMGeneral = this.props.ncmgeneralsReduc.ncmgeneral;
            if (selectedNCMGeneral && selectedNCMGeneral.code && selectedNCMGeneral.name) {
                selectedNCMGeneral.code_name = selectedNCMGeneral.code + ' ' + selectedNCMGeneral.name.toUpperCase();
                this.setState({ selectedNCMGeneral, inputValue: selectedNCMGeneral.code_name });
            }else{
                this.setState({ selectedNCMGeneral: null, inputValue: '' });
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setNCMGeneral(this.props.value);
        }
    }

    onChange = (e, index) => {
        const selectedNCM = e.value;
        if (selectedNCM && selectedNCM.id) {
            this.setState({ selectedNCMGeneral: selectedNCM, inputValue: selectedNCM.code_name });
            if (this.props.onChange) {
                this.props.onChange({ target: { name: this.props.name, value: selectedNCM.id } }, index);
            }
        } else {
            this.setState({ inputValue: e.value });
        }
    }

    render() {
        return (
            <>
                <Form.Label className="font-weight-bold custom-label-class" style={{ display: 'block', marginBottom: '4px' }}>NCM</Form.Label>
                <AutoComplete
                    value={this.state.inputValue}  // use inputValue aqui
                    suggestions={this.state.filteredNCMGenerals}
                    completeMethod={this.search}
                    onChange={this.onChange}
                    field="code_name"
                    placeholder="Digite o NCM do Produto"
                    style={{
                        width: '100%',
                        marginTop: '6px',
                        marginBottom: '6px',
                    }}
                    inputStyle={{
                        height: 'calc(1.5em + .75rem + 2px)',
                        padding: '.375rem .75rem',
                        border: '1px solid #ced4da',
                        borderRadius: '.25rem',
                        fontSize: '1rem',
                        width: '100%',
                    }}
                    name={`ncm_general_${this.props.index}`}
                />
                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                    Campo obrigatório.
                </Form.Control.Feedback>
            </>
        );
    }
}

AutoCompleteNCM.propTypes = {
    ncmgeneralsReduc: PropTypes.object.isRequired,
    getNCMGenerals: PropTypes.func.isRequired,
    getNCMGeneral: PropTypes.func.isRequired,
    required: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    index: PropTypes.number,
};

const mapStateToProps = state => ({
    ncmgeneralsReduc: state.ncmgeneralsReduc,
});

export default connect(mapStateToProps, { getNCMGenerals, getNCMGeneral })(withRouter(AutoCompleteNCM));
