import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IMaskInput } from "react-imask";
import { withRouter, Link } from "react-router-dom";
import { cnpjMask, cnpjValidation, onlyNumber} from "../../utils/mask"
import { DateTime } from 'luxon';
import { addCompany, updateCompany, getCompany } from "./CompanyActions";
import { getUfs } from "components/ufs/UfsActions"
import { getCitiesByUf } from "components/cities/CitiesActions"
import Loader from "components/utils/Loader"
import { putBlankProperties, blobToBase64, convertB64ToBlob, fixBlankProperties } from "../../utils/Utils";
import ControlPhone from "components/utils/ControlPhone";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion
} from "react-bootstrap";

// https://www.codingame.com/playgrounds/9396/redux-form-validation-tutorial

class AddCompany extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            co: {
                corporate_name: "",
                fantasy_name: "",
                cnpj:"",
                address:"",
                city:"",
                state: "",
                neighborhood:"",
                main_phone:"",
                secondary_phone:"",
                email:"",
                url:"",
                crc:"",
                clf:"",
                clf_validate_date:"",
                contract_date:"",
                contract_file:"",
                billing_start_date:"",
                number:"",
                enabled:true,
            
                production: false,
                transformation: false,
                consumption: true,
                factory: false,
                transport: true,
                storing: true,
                national: true,
                international: true
            },
            validated: false,
            loading: false
        }
        this.props.getUfs()
        this.props.companiesReduc.company = this.state.co
        //this.onChange = this.onChange.bind(this)
    }


    setValidated = (v) => {
        this.setState({ validated: v });
    }

    handleSubmit = (event) => {
        const form = document.getElementById("AddCompany")
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();            
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.companiesReduc.company.id !== prevState.co.id) {
            const { company } = nextProps.companiesReduc;
            nextProps.getCitiesByUf(company.state)
            company.number = company.number?.toString() || ""
            return {
                co: company,
                validated: prevState.validated
            };
        }
        else
            return null
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        if (id != null) {
            this.props.getCompany(id);
        }
        else {
            this.props.companiesReduc.company = this.state.co
        }
    }

    onChangeCheckbox = e => {        
        var co = { ...this.state.co }        
        co[e.target.name] = e.target.value === 'true' ? false : true
        this.setState({ co });
    }

    onChange = e => {
        var co = { ...this.state.co }
        co[e.target.name] = e.target.value        
        this.setState({ co });
    };

    onChangeUf = e => {
        this.onChange(e)
        this.setState({loading:true})
        this.props.getCitiesByUf(e.target.value).then(() => {
            this.setState({loading:false})
            var co = { ...this.state.co }
            co['city'] = ""
            this.setState({ co });
        })
    }
    
    onChangeCheckbox = e => {
        var co = { ...this.state.co }
        co[e.target.name] = e.target.value === 'true' ? false : true
        this.setState({ co });
    }

    onSaveClick = (event) => {
        let company = {...this.state.co};
        company.cnpj = onlyNumber(company.cnpj)        
        company.cep = onlyNumber(company.cep)
        company.number = onlyNumber(company.number)
        company.main_phone = onlyNumber(company.main_phone)
        company.crc = onlyNumber(company.crc)
        company.clf = onlyNumber(company.clf)
        company.secondary_phone = onlyNumber(company.secondary_phone)
        if (company.contract_date)
            company.contract_date = new Date(company.contract_date).toISOString().slice(0, 10)
        if (company.clf_validate_date)
            company.clf_validate_date = new Date(company.clf_validate_date).toISOString().slice(0, 10)
        if (company.billing_start_date)
            company.billing_start_date = new Date(company.billing_start_date).toISOString().slice(0, 10)

        company = fixBlankProperties(company)

        if (!this.state.co.id){
            this.setState({loading:true})
            this.props.addCompany(company).then(() => {
                this.setState({loading:false})
                this.props.history.push('/manage/companies');
                }
            )
            .catch(error => {
                this.setState({loading:false})
              }
            )
        }
        else{
            this.setState({loading:true})
            this.props.updateCompany(this.state.co.id, company).then(() => {
                this.setState({loading:false})
                this.props.history.push('/manage/companies');
                }
            ).catch(error => {
                this.setState({loading:false})
              }
            )
        }
    };

    

    render() {
        this.state.co.cnpj = cnpjMask(this.state.co.cnpj)

        const { ufs } = this.props.ufsReduc

        let uf_options = ufs.map(c => {
            return <option key={c.id} value={c.id}>{c.abreviation }</option>
        })

        const { cities } = this.props.citiesReduc

        let city_options = cities.map(c => {
            return <option key={c.id} value={c.id}>{c.city }</option>
        })

        return (
            <>
            <Loader loading={this.state.loading} />
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Cadastro de Empresas Gestoras</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form id="AddCompany" noValidate validated={this.state.validated}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="corporate_name">
                                            <Form.Label className="font-weight-bold">Nome</Form.Label>
                                            <Form.Control required type="text" name="corporate_name" placeholder="Razão Social" value={this.state.co.corporate_name}
                                                onChange={this.onChange} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="fantasy_name">
                                            <Form.Label className="font-weight-bold">Nome fantasia</Form.Label>
                                            <Form.Control required type="text" name="fantasy_name" placeholder="Nome Fantasia" value={this.state.co.fantasy_name}
                                                onChange={this.onChange} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="cnpj">
                                            <Form.Label className="font-weight-bold">CNPJ</Form.Label>
                                            <Form.Control required type="text" name="cnpj" placeholder="CNPJ" value={this.state.co.cnpj}
                                               onChange={this.onChange} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="address">
                                            <Form.Label className="font-weight-bold">Endereço</Form.Label>
                                            <Form.Control required type="text" name="address" placeholder="Endereço" value={this.state.co.address}
                                                onChange={this.onChange} maxLength={500} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="number">
                                            <Form.Label className="font-weight-bold">Número</Form.Label>
                                            <Form.Control type="text" name="number" placeholder="Número" value={this.state.co.number}
                                                onChange={this.onChange} maxLength={5} as={IMaskInput} mask="0000000000" />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="cep">
                                            <Form.Label className="font-weight-bold">CEP</Form.Label>
                                            <Form.Control required type="text" name="cep" placeholder="CEP" value={this.state.co.cep}
                                               onChange={this.onChange} as={IMaskInput} mask="00000-000" maxLength={10} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="neighborhood">
                                            <Form.Label className="font-weight-bold">Bairro</Form.Label>
                                            <Form.Control required type="text" name="neighborhood" placeholder="Bairro" value={this.state.co.neighborhood}
                                                onChange={this.onChange} maxLength={50} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="state">
                                            <Form.Label className="font-weight-bold">Estado</Form.Label>
                                            <Form.Control as="select" required name="state" placeholder="Estado" value={this.state.co.state}
                                               onChange={this.onChangeUf} >
                                                <option value="">--- SELECIONE ---</option>
                                                {uf_options}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="city">
                                            <Form.Label className="font-weight-bold">Cidade</Form.Label>
                                            <Form.Control required as="select" name="city" placeholder="Cidade" value={this.state.co.city}
                                                onChange={this.onChange} maxLength={50} >                                            
                                                <option value="">--- SELECIONE ---</option>
                                                {city_options}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="url">
                                            <Form.Label className="font-weight-bold">URL</Form.Label>
                                            <Form.Control type="text" name="url" placeholder="tst.com" value={this.state.co.url}
                                               onChange={this.onChange} maxLength={250} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="crc">
                                            <Form.Label className="font-weight-bold">CRC</Form.Label> 
                                            <Form.Control type="text" name="crc" as={IMaskInput} mask="0000-00000000" placeholder="2021-12345678" value={this.state.co.crc}
                                                onChange={this.onChange} maxLength={13} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="clf">
                                            <Form.Label className="font-weight-bold">CLF</Form.Label>                                            
                                            <Form.Control type="text" name="clf" as={IMaskInput} mask="0000-00000000" placeholder="2020-00570513" value={this.state.co.clf}
                                                onChange={this.onChange} maxLength={13} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="clf_validate_date">
                                            <Form.Label className="font-weight-bold">Data Validade CLF</Form.Label>
                                            <Form.Control type="Date" name="clf_validate_date" value={DateTime.fromISO(this.state.co.clf_validate_date, {setZone: true}).toFormat('yyyy-MM-dd')}
                                                onChange={this.onChange} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="4" controlId="email">
                                            <Form.Label className="font-weight-bold">E-mail</Form.Label>
                                            <Form.Control required type="text" name="email" placeholder="fulano@domain.com" value={this.state.co.email}
                                               onChange={this.onChange} maxLength={250} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="main_phone">
                                            <ControlPhone label="Telefone principal" name="main_phone" required={true} value={this.state.co.main_phone} onChangePhone={this.onChange} />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="secondary_phone">
                                            <ControlPhone label="Telefone opcional" name="secondary_phone" required={false} value={this.state.co.secondary_phone} onChangePhone={this.onChange} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        
                                        <Form.Group as={Col} md="4"> 
                                            <Form.Label className="font-weight-bold" >Ativo</Form.Label>
                                            <Form.Check id="enabled" name="enabled"
                                              className="mb-1 pl-0">
                                                <Form.Check.Label>
                                                <Form.Check.Input id="enabled" name="enabled" onChange={this.onChangeCheckbox}
                                                    checked = {this.state.co.enabled}
                                                    value = {this.state.co.enabled}
                                                    type="checkbox"
                                                ></Form.Check.Input>
                                                <span className="form-check-sign"></span>
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </Form.Group>
                                    </Row>

                                    <Row>
                                            <Col md="12">
                                                <h5>Atividades</h5>
                                                <hr />
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Produção</Form.Label>
                                                <Form.Check id="production" name="production"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="production" name="production" onChange={this.onChangeCheckbox}
                                                            checked={this.state.co.production}
                                                            value={this.state.co.production}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Transformação</Form.Label>
                                                <Form.Check id="transformation" name="transformation"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="transformation" name="transformation" onChange={this.onChangeCheckbox}
                                                            checked={this.state.co.transformation}
                                                            value={this.state.co.transformation}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Consumo</Form.Label>
                                                <Form.Check id="consumption" name="consumption"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="consumption" name="consumption" onChange={this.onChangeCheckbox}
                                                            checked={this.state.co.consumption}
                                                            value={this.state.co.consumption}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Fabricação</Form.Label>
                                                <Form.Check id="factory" name="factory"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="factory" name="factory" onChange={this.onChangeCheckbox}
                                                            checked={this.state.co.factory}
                                                            value={this.state.co.factory}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Transporte</Form.Label>
                                                <Form.Check id="transport" name="transport"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="transport" name="transport" onChange={this.onChangeCheckbox}
                                                            checked={this.state.co.transport}
                                                            value={this.state.co.transport}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Armazenagem</Form.Label>
                                                <Form.Check id="storing" name="storing"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="storing" name="storing" onChange={this.onChangeCheckbox}
                                                            checked={this.state.co.storing}
                                                            value={this.state.co.storing}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Nacional</Form.Label>
                                                <Form.Check id="national" name="national"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="national" name="national" onChange={this.onChangeCheckbox}
                                                            checked={this.state.co.national}
                                                            value={this.state.co.national}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label className="font-weight-bold" >Internacional</Form.Label>
                                                <Form.Check id="international" name="international"
                                                    className="mb-1 pl-0">
                                                    <Form.Check.Label>
                                                        <Form.Check.Input id="international" name="international" onChange={this.onChangeCheckbox}
                                                            checked={this.state.co.international}
                                                            value={this.state.co.international}
                                                            type="checkbox"
                                                        ></Form.Check.Input>
                                                        <span className="form-check-sign"></span>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Form.Group>
                                        </Row> 
                                </Form>
                            </Card.Body>
                        </Card>
                        <Button className="btn btn btn-primary active btn-floating position-sticky" style={{ bottom: '20px', right: '20px'}} type="button" onClick={this.handleSubmit} >
                            Salvar
                        </Button>{' '}
                        <Link to="/manage/companies/"className="btn btn btn-danger active btn-floating position-sticky" style={{ bottom: '20px', right: '20px'}}>
                            Cancelar
                        </Link>
                    </Col>
                </Row>
            </Container>
            </>
        )
    }
}

AddCompany.propTypes = {
    addCompany: PropTypes.func.isRequired,
    updateCompany: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,
    companiesReduc: PropTypes.object.isRequired,
    getUfs: PropTypes.func.isRequired,
    getCitiesByUf: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    companiesReduc: state.companiesReduc,
    ufsReduc: state.ufsReduc,
    citiesReduc: state.citiesReduc
});

export default connect(mapStateToProps, { addCompany, updateCompany, getCompany, getUfs, getCitiesByUf })(withRouter(AddCompany));