import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IMaskInput } from "react-imask";
import { withRouter, Link } from "react-router-dom";
import { cnpjMask, cnpjValidation, onlyNumber, quantidadeMask, convertDecimalPlaces} from "../../utils/mask"
import {onChangeKilos, onChangeLiters} from "../../utils/CalcLitersKilos"
import { DateTime } from 'luxon';
import { addMovement, updateMovement, getMovement, modalAddMovement } from "./MovementActions";
import { getCommercialProducts, getCommercialProduct } from "components/commecialproduct/CommercialProductActions"
import { getProducts, getProduct } from "components/product/ProductActions";
import Loader from "components/utils/Loader"
import { getNCMSynonymsByNCMGeneral} from "../ncmsynonym/NCMSynonymActions"
import { putBlankProperties, blobToBase64, convertB64ToBlob, fixBlankProperties, toastOnError } from "../../utils/Utils";
import { getMuOptions, getMovementTypeOptions, getMovementSubTypeOptions } from "utils/OptionsUtils"
import { InputNumber } from 'primereact/inputnumber';
import { TrashFill} from 'react-bootstrap-icons';
import { getProfileByLoggedUser } from "../persons/PersonsActions";
import ControlKilosLiters from "components/utils/ControlKilosLiters";
import AutoCompleteNCM from "components/utils/AutoCompleteNCM";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion
} from "react-bootstrap";
import Movement from "./Movement";

// https://www.codingame.com/playgrounds/9396/redux-form-validation-tutorial

class AddMovement extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            mv: {
                movement_type: "",
                movement_subtype: "",
                liters:"",
                kilos:"",
                isLiters:"",
                reference_date: ""
            },
            product:{
                id:"",
                density:"",
                concentration:""
            },
            commercial_product: "",            
            el: {
                id:"",
                company: "",
                density: ""
            },
            sps: [{
                pr:{
                    id:"",
                    ncm_general: "",                    
                    concentration:"",
                    stocked_product:"",
                    liters: "",
                    kilos:"",
                },
            }],
            validated: false,
            loading: false
        }
        this.fetchUserProfile();
        // this.props.getCommercialProducts();       
        this.onChange = this.onChange.bind(this)
        this.onChangeNCMGeneral = this.onChangeNCMGeneral.bind(this)
    }
    fetchUserProfile = () => {
        this.props.getProfileByLoggedUser(this.props.auth.user)
          .then(() => {
        
            const {persons} = this.props.personsReduc;
            this.setState(prevState => ({
              el: {
                ...prevState.el,
                company: persons[0].company_cnpj
              }
            }));
        }  )
      }
    setValidated = (v) => {
        this.setState({ validated: v });
    }

    handleSubmit = (event) => {
        const form = document.getElementById("AddMovement")

        if (this.state.mv.liters[0] === "," || this.state.mv.kilos[0] === ","){
            toastOnError("Utilize vírgula apenas como separador decimal tanto para litros quanto para kilos")
            this.setValidated(false);
            form.checkValidity()
            return
        }

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();            
        }
    };    

    static getDerivedStateFromProps(nextProps, prevState) {
        // if (nextProps.movementsReduc.movement.id !== prevState.el.id) {
        //     var { movement } = nextProps.movementsReduc;
        //     movement = putBlankProperties(movement)           
            
        //     if (movement.id){
        //         var st = AddMovement.parser(movement)
        //         return {
        //             el:st.el,
        //             sps:st.sps,
        //             validated: prevState.validated
        //         };
        //     }
        //     else
        //         return null
        // }
        // else
        //     return null
        return null
    }

    static parser(commercialproduct){
        var st = {}
        st.el = {...commercialproduct}
        st.sps = []
        if (commercialproduct.substances)
            commercialproduct.substances.map((e, index) => st.sps.push({pr: e}))
        delete st.el.substances
        return st
    }

    componentDidMount() {
        if (!this.props.isModalAddMovement){
            const { id } = this.props.match.params;
            if (id != null) {
                this.setState({loading:true})

                this.props.getMovement(id).then(() => {                    
                    var st = AddMovement.parser(this.props.movementsReduc.movement)                    
                    this.setState({el: st.el, sps: st.sps, loading:false})
                })
            }
            else {
                this.props.movementsReduc.movement = this.state.el
            }
        }
        else{            
            var el = this.props.movementsReduc.movement
            this.setState({el})            
        }
    }

    onChangeCheckbox = e => {        
        var el = { ...this.state.el }        
        el[e.target.name] = e.target.value === 'true' ? false : true
        this.setState({ el });
    }

    onChangeNCMGeneral = e => {
        this.onChange(e)
        this.setState({loading:true})
        this.props.getNCMSynonymsByNCMGeneral(e.target.value).then(() => {
            this.setState({loading:false})
            var el = { ...this.state.el }
            el['ncm_synonym'] = ""
            this.setState({ el });
        })
    }

    onChangeCP = e => {
        this.setState({ loading: true })

        this.props.getCommercialProduct(e.target.value).then(() => {
            var { commercialproduct } = this.props.commercialproductsReduc

            commercialproduct = putBlankProperties(commercialproduct)            
            var st = AddMovement.parser(commercialproduct)

            var state = {...this.state}
            state.commercial_product = st.el.id
            state.el = st.el
            
            state.sps = st.sps
            state.loading = false

            this.setState(state)
        })
        .catch(error => {
            this.setState({ loading: false })
        })
    }

    onChangeP = e => {
        var state = {... this.state}
        state['mv']['liters'] = ''
        state['mv']['kilos'] = ''
        if (e.target.value){
            this.setState({ loading: true })
            this.props.getProduct(e.target.value).then(() => {
                var { product } = this.props.productsReduc
                product = putBlankProperties(product)
                state.product = product
                state.loading = false
                this.setState(state)
            })
            .catch(error => {
                this.setState({ loading: false })
            })
        }else{
            state[e.target.name]['id'] = e.target.value
            this.setState(state)
        }
    }

    onChangeMVType = e => {
        this.onChangeMV(e)
        this.setState({loading:true})
        this.props.getProducts({is_message_to_user:false, withStock:1}).then(() => {
            this.setState({loading:false})
        })
        .catch(err => {
            this.setState({loading:false})
        })

        // this.props.getCommercialProducts(this.state.el.company).then(() => {            
        //     this.setState({loading:false})            
        //     console.log('loaded commercial products')            
        // })
        // .catch(err => {
        //     this.setState({loading:false})
        // })
    }

    onChangeLiters = e => {
        var kilos = onChangeLiters(e, this.state.product.density, this)
        this.onChangeMV(e, "kilos", kilos)
    }

    onChangeKilos = e => {
        var liters = onChangeKilos(e, this.state.product.density, this)
        this.onChangeMV(e, "liters", liters)
    }

    onChangeMV = (e, name=null, value=null) => {
        var mv = { ...this.state.mv }
        if (name){
            mv[name]=convertDecimalPlaces(value)
            if (name === 'liters' || name === 'kilos')
                if (value)
                    mv['isLiters'] = name !== 'liters'
                else
                    mv['isLiters'] = ""
        }
        mv[e.target.name] = e.target.value
        this.setState({ mv });
    }

    onChange = e => {
        var el = { ...this.state.el }
        el[e.target.name] = e.target.value        
        this.setState({ el });
    };

    onChangeCnpj = e => {
        e.target.value = cnpjMask(e.target.value)
        this.onChange(e)
        let cnpj = e.target.value
        if (cnpjValidation(cnpj)) {
            var numcnpj = cnpj.replace(/\D+/g, '')
        }
    };

    onSaveClick = (event) => {
        var repo = {}
        repo.movement_type = this.state.mv.movement_type
        repo.movement_subtype = this.state.mv.movement_subtype
        repo.liters = this.state.mv.liters.replace().replace(',', '.')
        repo.kilos = this.state.mv.kilos.replace().replace(',', '.')
        repo.isLiters = this.state.mv.isLiters
        repo.reference_date = DateTime.fromISO(this.state.mv.reference_date, {setZone: true}).toFormat('yyyy-MM-dd')

        if (this.state.product.id){
            repo.product = {...this.state.product}
        }
        else{
            repo.commercial = {...this.state.el}
            repo.commercial.company = onlyNumber(repo.commercial.company)
            repo.commercial = fixBlankProperties(repo.commercial)
            repo.products = []
            this.state.sps.map((item, index) => {
                var p = {}
                p.id = item.pr.id ?? ""
                p.ncm_general = item.pr.ncm_general
                p.concentration = item.pr.concentration
                p.density = item.pr.density            
                p = fixBlankProperties(p)
                repo.products.push(p)
            })
        }

        if (!this.state.el.id){
            this.setState({loading:true})
            this.props.addMovement(repo).then(() => {
                this.checkAfterSave()
                }
            )
            .catch(error => {
                this.setState({loading:false})
              }
            )
        }
        else{
            this.setState({loading:true})
            this.props.addMovement(repo).then(() => {
                this.checkAfterSave()
                }
            ).catch(error => {
                this.setState({loading:false})
              }
            )
        }
    };

    checkAfterSave = () => {
        this.setState({loading:false})
        if (this.props.isModalAddMovement)
            this.props.modalAddMovement(null)
        else
            this.props.history.push('/manage/movements');
    }

    onCancel = () => {
        this.checkAfterSave()
    }

    onChangeProduct = (e, index, type = 'pr', name = null, value=null) => {
        var sps = this.state.sps        
        var spsIndexed = sps[index]
        var obj = {...spsIndexed[type]}
        if (name){
            obj[name] = value
        }else{
            obj[e.target.name] = e.target.value         
        }        
        sps[index][type] = obj
        this.setState({sps})
    }

    onChangeNCMGeneral = (e, index) => {
        this.onChangeProduct(e, index)
        this.setState({loading:true})
        this.props.getNCMSynonymsByNCMGeneral(e.target.value).then(() => {
            this.setState({loading:false})

            const { ncmsynonyms } = this.props.ncmsynonymsReduc
            var ncmsynonyms_options = ncmsynonyms.map(c => {
                return <option key={c.id} value={c.id}>{c.name }</option>
            })
            this.onChangeProduct(e, index, 'pr', 'ncm_synonym', "")
            this.onChangeProduct(e, index, 'ncmsynonyms', 'options', ncmsynonyms_options)
        })
    }

    onRemoveSpFromReview = () => {
        var sps = this.state.sps
        sps.pop()
        this.setState({sps})
    }

    addSpToReview = () => {
        var sps = this.state.sps
        sps.push({
            ncmsynonyms: {options: []},
            pr:{
                ncm_general: "",
                concentration:"",
                enabled:true
            }
        })
        this.setState({sps})
    }

    render() {        
        if (this.state.el.company)
            this.state.el.company = cnpjMask(this.state.el.company)

        const { ncmgenerals } = this.props.ncmgeneralsReduc

        let ncmgenerals_options = ncmgenerals.map(c => {
            return <option key={c.id} value={c.id}>{ c.name + ' ' + c.code }</option>
        })

        const { commercialproducts } = this.props.commercialproductsReduc;

        let commercialproducts_options = commercialproducts.map(c => {
            return <option key={c.id} value={c.id}>{ c.name }</option>
        })

        const { products } = this.props.productsReduc
        let products_options = products.map(c => {          
            var commercial_name = c.commercial_name ? "- " + c.commercial_name.toUpperCase() : ""  
            return <option key={c.id} value={c.id}>{ `${c.ncm_general_code} ${c.ncm_general_name} ${commercial_name} | Conc ${c.concentration}% - Dens ${c.density }` }</option>
        })
        let i = 0
        return (
            <>
            <Loader loading={this.state.loading} />
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Cadastro de Utilização</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form id="AddMovement" noValidate validated={this.state.validated}>
                                    <Row className="mb-3">
                                        {/* <Form.Group as={Col} md="4" controlId="company">
                                            <Form.Label className="font-weight-bold">CNPJ Gestora</Form.Label>
                                            <Form.Control
                                            as= "select" required  name="company" placeholder="CNPJ" value={this.state.el.company}
                                                onChange={this.onChangeCnpj} maxLength={200} disabled >
                                            {cnpjGestora}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group> */}

                                        <Form.Group as={Col} md="4" controlId="movement_type">                                            
                                            <Form.Label required className="font-weight-bold" >Tipo de Utilização</Form.Label>
                                            <Form.Control
                                                as="select" required aria-label="movement_type" name="movement_type" onChange={e => this.onChangeMVType(e)} value={this.state.mv.movement_type}>
                                                <option value="">--- SELECIONE ---</option>
                                                {getMovementTypeOptions(0)}
                                                </Form.Control>
                                            <Form.Control.Feedback >Ok</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="movement_subtype">                                            
                                            <Form.Label required className="font-weight-bold" >Motivo</Form.Label>
                                            <Form.Control
                                                as="select" required={getMovementSubTypeOptions(0, this.state.mv.movement_type)} aria-label="movement_subtype" name="movement_subtype" onChange={e => this.onChangeMV(e)} value={this.state.mv.movement_subtype}>
                                                <option value="">--- SELECIONE ---</option>
                                                {getMovementSubTypeOptions(0, this.state.mv.movement_type)}
                                            </Form.Control>
                                            <Form.Control.Feedback >Ok</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="reference_date">
                                            <Form.Label className="font-weight-bold">Data de Referência</Form.Label>
                                            <Form.Control required type="date" name="reference_date"
                                                value={DateTime.fromISO(this.state.mv.reference_date, {setZone: true}).toFormat('yyyy-MM-dd')}
                                                placeholder="Data de Referência"
                                                onChange={e => this.onChangeMV(e)} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    {
                                         this.state.mv.movement_type &&
                                    <>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="product">
                                            <Form.Label className="font-weight-bold" >Produto</Form.Label>
                                            <Form.Control 
                                                as="select" required aria-label="Produto" name="product" onChange={e => this.onChangeP(e)} value={this.state.product.id}>
                                                <option value="">--- SELECIONE ---</option>
                                                {products_options}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                        </Form.Group>

                                        {
                                            this.state.product &&
                                            <>

                                        <Form.Group as={Col} md="3" controlId="kilos">
                                            <ControlKilosLiters onChangeMU={e => this.onChangeKilos(e)}
                                            req={!this.state.mv.liters && !this.state.mv.liters} dis={this.state.mv.isLiters && this.state.mv.liters !== ""}
                                            value={this.state.mv.kilos} label="Kilos" name="kilos" />
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="liters">
                                            <ControlKilosLiters onChangeMU={e => this.onChangeLiters(e)}  
                                                            req={!this.state.mv.liters && !this.state.mv.liters} dis={!this.state.mv.isLiters && this.state.mv.kilos !== ""} 
                                                            value={this.state.mv.liters} label="Litros" name="liters"/>

                                            
                                        </Form.Group>

                                        </> }

                                        {/* <Form.Group as={Col} md="6" controlId="commercial_product">
                                            <Form.Label className="font-weight-bold" >Produto Comercial</Form.Label>
                                            <Form.Control 
                                                as="select" required aria-label="Produto" name="commercial_product" onChange={e => this.onChangeCP(e)} value={this.state.commercial_product}>
                                                <option value="">--- SELECIONE ---</option>
                                                {commercialproducts_options}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                        </Form.Group> */}

                                        {/* <Form.Group as={Col} md="2" controlId="density">
                                            <Form.Label className="font-weight-bold">Densidade</Form.Label>
                                            <Form.Control disabled type='number' step="0.01" name="density" placeholder="Densidade" value={this.state.el.density}
                                                onChange={e => this.onChange(e)}  />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group> */}
                                    </Row>

                                    {/* <Row>
                                        <h5 className="text-center text-secondary font-weight-bold"><b>Fórmula</b></h5> <hr></hr>
                                    </Row>
                                    {this.state.sps.map((row, index) => {
                                    return (
                                    <div key={i++}>
                                    <Row key={i++} className="mb-3">
                                        <Form.Group key={i++} as={Col} md="4">
                                            <Form.Label key={i++} className="font-weight-bold" >NCM</Form.Label>
                                            <Form.Control key={i++} disabled
                                                as="select" required aria-label="NCM" name="ncm_general" onChange={e => this.onChangeNCMGeneral(e, index)} value={row.pr.ncm_general}>
                                                <option value="">--- SELECIONE ---</option>
                                                {ncmgenerals_options}
                                            </Form.Control>
                                            <Form.Control.Feedback key={i++}>Ok</Form.Control.Feedback>
                                        </Form.Group>                                       

                                        <Form.Group key={i++} as={Col} md="2" controlId="concentration">
                                            <Form.Label key={i++} className="font-weight-bold">Concentração</Form.Label>
                                            <Form.Control key={i++} disabled type='number' step="1" name="concentration" placeholder="concentration" value={row.pr.concentration}
                                                onChange={e => this.onChangeProduct(e, index)}  />
                                            <Form.Control.Feedback key={i++}>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback key={i++} type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group key={i++} as={Col} md="3" controlId="liters">
                                            <Form.Label key={i++} className="font-weight-bold">Litros</Form.Label>
                                            <Form.Control key={i++} required={!row.pr.liters && !row.pr.liters} disabled={row.pr.kilos} type='number' step="1" name="liters" placeholder="Quantidades em litros" value={row.pr.liters}
                                                onChange={e => this.onChangeProduct(e, index)}  />
                                            <Form.Control.Feedback key={i++}>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback key={i++} type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group key={i++} as={Col} md="3" controlId="kilos">
                                            <Form.Label key={i++} className="font-weight-bold">Kilos</Form.Label>
                                            <Form.Control key={i++} required={!row.pr.liters && !row.pr.liters} disabled={row.pr.liters} type='number' step="1" name="kilos" placeholder="Quantidade em kilos" value={row.pr.kilos}
                                                onChange={e => this.onChangeProduct(e, index)}  />
                                            <Form.Control.Feedback key={i++}>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback key={i++} type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>



                                        {this.state.sps.length == index + 1 && <Col key={i++} md="1">
                                            <br/>
                                        </Col>}
                                    </Row>                                    
                                    </div>)
                                    }
                                    )} */}
                                    </>}
                                </Form>
                            </Card.Body>
                        </Card>
                        <Button className="btn btn btn-primary active btn-floating position-sticky" style={{ bottom: '20px', right: '20px'}} type="button" onClick={this.handleSubmit} >
                            Salvar
                        </Button>{' '}
                        <Button onClick={this.onCancel} className="btn btn-danger active btn-floating position-sticky" style={{ bottom: '20px', right: '20px'}}>
                            Cancelar
                        </Button>
                    </Col>
                </Row>
            </Container>
            </>
        )
    }
}

AddMovement.propTypes = {
    addMovement: PropTypes.func.isRequired,
    updateMovement: PropTypes.func.isRequired,
    getCommercialProducts: PropTypes.func.isRequired,
    getCommercialProduct: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired,
    getMovement: PropTypes.func.isRequired,
    movementsReduc: PropTypes.object.isRequired,
    ncmgeneralsReduc: PropTypes.object.isRequired,
    ncmsynonymsReduc: PropTypes.object.isRequired,
    modalAddMovement: PropTypes.func.isRequired,
    getNCMSynonymsByNCMGeneral: PropTypes.func.isRequired,
    commercialproductsReduc: PropTypes.object.isRequired,
    productsReduc: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getProfileByLoggedUser: PropTypes.func.isRequired,
    personsReduc:PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    movementsReduc: state.movementsReduc,
    ncmgeneralsReduc: state.ncmgeneralsReduc,
    ncmsynonymsReduc: state.ncmsynonymsReduc,
    isModalAddMovement: state.movementsReduc.isModalAddMovement,
    commercialproductsReduc: state.commercialproductsReduc,
    productsReduc: state.productsReduc,
    personsReduc: state.personsReduc, 
    auth: state.auth
});

export default connect(mapStateToProps, { addMovement, updateMovement, getMovement,
     getNCMSynonymsByNCMGeneral, modalAddMovement,getProfileByLoggedUser, getCommercialProducts, getCommercialProduct, getProducts, getProduct })(withRouter(AddMovement));