import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IMaskInput } from "react-imask";
import { withRouter, Link } from "react-router-dom";
import { cnpjMask, cnpjValidation, onlyNumber, densityMask, quantidadeMask, convertDecimalPlaces } from "../../utils/mask"
import { DateTime } from 'luxon';
import { addStock, updateStock, getStock, addStockInBatch } from "./StockActions";
import Loader from "components/utils/Loader"
import { putBlankProperties, blobToBase64, convertB64ToBlob, fixBlankProperties, isEmpty, toastOnError } from "../../utils/Utils";
import { getNCMSynonymsByNCMGeneral } from "../ncmsynonym/NCMSynonymActions"
import { addProduct, updateProduct, getProduct, getProducts } from "components/product/ProductActions";
import { getUfs } from "components/ufs/UfsActions"
import { getCitiesByUf } from "components/cities/CitiesActions"
import {onChangeKilos, onChangeLiters} from "../../utils/CalcLitersKilos"
import ControlDensity from "components/utils/ControlDensity"
import {getSuppliers} from "components/supplier/SupplierActions"
import {getDestinations} from "components/destination/DestinationActions"
import {getOrigins} from "components/origin/OriginActions"
import { getAcquirers } from "components/acquirer/AcquirerActions";
import ControlPhone from "components/utils/ControlPhone";
import ControlConcentration from "components/utils/ControlConcentration";
import ControlKilosLiters from "components/utils/ControlKilosLiters";
import { PencilFill, TrashFill, Download} from 'react-bootstrap-icons';
import { Panel } from 'primereact/panel';
import { getNf } from "../nf/NfActions";
import { Message } from 'primereact/message';
import AutoCompleteNCM from "components/utils/AutoCompleteNCM";

// react-bootstrap components
import {
    Button,
    Card,
    Form,
    Container,    
    Row,
    Col
} from "react-bootstrap";

class AddStock extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            nfrepo: {
                nf: "",
                issued_at: "",
                type: "EST",
                cnpj_customer: "",
            },
            su: {
                corporate_name: "",
                fantasy_name: "",
                cnpj: "",
                address: "",
                cities: [],
                city: "",
                state: "",
                number: "",
                cep: "",
                neighborhood: "",
                main_phone: "",
                secondary_phone: "",
                enabled: true
            },
            or: {
                corporate_name: "",
                fantasy_name: "",
                cnpj: "",
                address: "",
                cities: [],
                city: "",
                state: "",
                number: "",
                cep: "",
                neighborhood: "",
                main_phone: "",
                secondary_phone: "",
                enabled: true
            },
            de: {
                corporate_name: "",
                fantasy_name: "",
                cnpj: "",
                address: "",
                cities: [],
                city: "",
                state: "",
                number: "",
                cep: "",
                neighborhood: "",
                main_phone: "",
                secondary_phone: "",
                enabled: true
            },
            sps: [{
                ncmsynonyms: { options: [] },
                pr: {
                    name: "",
                    ncm_general: "",
                    commercial_name: "",
                    concentration: "",
                    density: "",
                    supplier: "",
                    enabled: true
                },
                sp: {
                    product: "",
                    quantity: "",
                    liters: "",
                    kilos: "",
                    isLiters: "",
                    enabled: true
                }
            }],
            require_destination: false,
            require_transporter: false,
            transporter_type: "", // F, A, T
            validated: false,
            loading: false,
            alert: ''
        }
        this.props.getUfs()
    }

    load_company = (type, object) => {
        if (object){
            var obj = { ...this.state[type] }
            obj = object
            obj.cnpj = cnpjMask(obj.cnpj)
            obj.cities = []
            this.state[type] = putBlankProperties(obj)
            this.setState(this.state)
            this.props.getCitiesByUf(obj.state).then(() => {
                this.state[type]['cities'] = this.props.citiesReduc.cities
                this.setState(this.state);
            }).catch((error) => {
                this.setState({ loading: false })
            })
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const stock = params.get('stock');
        if (stock === 'FIX' || stock === 'OUT') {
            var nfrepo = { ...this.state.nfrepo }
            nfrepo.type = stock
            this.setState({ nfrepo, loading: true})
            this.props.getProducts({is_message_to_user:false, withStock: stock === 'OUT' ? 1 : null})
            .catch((error) => {})
            .finally(() => {
                this.setState({ loading: false })
            });
        }

        if (id != null) {
            this.setState({ loading: true })
            this.props.getNf(id).then(() => {
                const { nf } = this.props.nfsReduc
                
                var nfrepo = { ...this.state.nfrepo }
                var alert = { ...this.state.alert }
                alert = 'Inteligência artificial foi aplicada para extrair os dados da nota fiscal. Favor revisar, principalmente, os dados relativos aos produtos extraídos tais como densidade, concentração, quantidades e unidades (Kg ou L).'
                nfrepo.nf = nf.number
                nfrepo.cnpj_customer = nf?.supplier?.cnpj_customer         
                nfrepo.issued_at = DateTime.fromISO(nf.issued_at, {setZone: true}).toFormat('yyyy-MM-dd')
                this.state.transporter_type = nf.transport_type
                if (!this.state.transporter_type){
                    this.state.transporter_type = ''
                }
                if (this.state.nfrepo.type === 'EST'){
                    this.load_company('su', nf.supplier)
                }else if (this.state.nfrepo.type === 'OUT'){
                    this.load_company('su', nf.acquirer)
                }
                this.load_company('or', nf.origin)
                this.load_company('de', nf.destination)

                var sps = []

                nf.itens.map((item, index) => {
                    item = putBlankProperties(item)
                    var sp = {
                        liters: item.total_liters?.replace('.', ','),
                        kilos: item.total_kilos?.replace('.', ','),
                        isLiters: item.is_liters,
                        enabled: true,
                        product: '',
                        quantity: '',
                    }
                    var pr = {
                        ncm_general: item.ncm_general,
                        // commercial_name: item.description,
                        concentration: JSON.stringify(item.concentration),
                        density: item.density?.replace('.', ','),
                        enabled: true,
                        name: '',
                        commercial_name: '',

                    }
                    sps.push({ pr, sp })
                })
                this.setState({ nfrepo, sps, loading: false, alert })

            }).catch((error) => {
                this.setState({ loading: false })
            })
        }
        else {
            this.props.stocksReduc.stock = this.state.sp
        }
    }

    setValidated = (v) => {
        this.setState({ validated: v });
    }

    handleSubmit = (event) => {
        const form = document.getElementById("AddStock")

        for(var i = 0; i < this.state.sps.length; i++){
            var item = this.state.sps[i]
            if (item.sp.liters[0] === "," || item.sp.kilos[0] === "," || item.pr.density[0] === ","){
                toastOnError("Utilize vírgula apenas como separador decimal tanto para litros quanto para kilos")
                this.setValidated(false);
                form.checkValidity()
                return
            }
        }

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        // if (nextProps.stocksReduc.stock.id !== prevState.sp.id) {
        //     const { stock } = nextProps.stocksReduc;
        //     stock.number = stock.number.toString()
        //     return {

        //         co: stock,
        //         validated: prevState.validated
        //     };
        // }
        // else
        //     return null
        return null
    }
    

    onChangeCheckbox = e => {
        var co = { ...this.state.co }
        co[e.target.name] = e.target.value === 'true' ? false : true
        this.setState({ co });
    }

    onChangeCnpj = e => {
        e.target.value = cnpjMask(e.target.value)
        this.onChangeNF(e)
        let cnpj = e.target.value
        if (cnpjValidation(cnpj)) {
            var numcnpj = cnpj.replace(/\D+/g, '')
            // this.props.getSuspectByCNPJ(numcnpj)
        }
    };

    onChangeCnpjForAny = (e, type, fn) => {
        var cnpj = onlyNumber(e.target.value)
        e.target.value = cnpjMask(cnpj)
        var obj = { ...this.state[type] }
        obj.cnpj =  e.target.value
        if (cnpj.length === 14) {
            this.state[type] = obj
            this.state.loading = true
            this.setState(this.state)
            fn({cnpj:cnpj}).then(() => {
                var reducedobjs = [];
                if (type === 'su' && this.state.nfrepo.type === 'EST') {
                    const { suppliers } = this.props.suppliersReduc
                    reducedobjs = suppliers
                }
                else if (type === 'su' && this.state.nfrepo.type === 'OUT') {
                    const { acquirers } = this.props.acquirersReduc
                    reducedobjs = acquirers
                }
                else if (type === 'or') {
                    const { origins } = this.props.originsReduc
                    reducedobjs = origins
                }
                else if (type === 'de') {
                    const { destinations } = this.props.destinationsReduc
                    reducedobjs = destinations
                }
                if (reducedobjs.length > 0) {
                    var obj = { ...this.state[type] }
                    obj = reducedobjs[0]
                    obj.cnpj = cnpjMask(obj.cnpj)
                    obj.cities = []
                    this.state[type] = putBlankProperties(obj)
                    this.state.loading = false
                    this.setState({type:obj})
                    this.props.getCitiesByUf(obj.state).then(() => {
                        this.state.loading = false
                        this.state[type]['cities'] = this.props.citiesReduc.cities
                        this.setState(this.state);
                    }).catch((error) => {
                        this.setState({ loading: false })
                    })
                }
                else {
                    this.setState({loading:false})
                }
            }).catch((error) => {
                this.setState({ loading: false })
            })
        }else{
            obj.id = ''
            this.state[type] = obj
            this.setState(this.state)
        }
    }

    onChangeCnpjForCompany = e => {
        if (this.state.nfrepo.type === 'EST') {
            this.onChangeCnpjForAny(e, 'su', this.props.getSuppliers)
        }else{
            this.onChangeCnpjForAny(e, 'su', this.props.getAcquirers)
        }
    };

    onChangeCnpjForOrigin = e => {
        this.onChangeCnpjForAny(e, 'or', this.props.getOrigins)
    };

    onChangeCnpjForDestination = e => {
        this.onChangeCnpjForAny(e, 'de', this.props.getDestinations)
    }

    addSpToReview = () => {
        var sps = this.state.sps
        sps.push({
            ncmsynonyms: { options: [] },
            pr: {
                name: "",
                ncm_general: "",
                commercial_name: "",
                concentration: "",
                density: "",
                supplier: "",
                enabled: true
            },
            sp: {
                product: "",
                liters: "",
                kilos: "",
                isLiters: "",
                nf: "",
                enabled: true
            }
        })
        this.setState({ sps })
    }
    onRemoveSpFromReview = (index) => {
        var sps = this.state.sps
        sps.splice(index, 1)
        this.setState({ sps })
    }
    changeDestination = () => {
        var require_destination = !this.state.require_destination
        this.setState({ require_destination })
    }

    changeTransporter = (e) => {
        var require_transporter = false
        if (e.target.value === 'T') {
            require_transporter = true
        }
        var transporter_type = e.target.value
        this.setState({ require_transporter, transporter_type })
    }

    onChangeNF = e => {
        var nfrepo = { ...this.state.nfrepo }
        nfrepo[e.target.name] = e.target.value
        this.setState({ nfrepo })
    }

    onChangeUf = (e, onpchange, type = 'co') => {
        onpchange(e)
        this.setState({ loading: true })
        this.props.getCitiesByUf(e.target.value).then(() => {
            this.state.loading = false
            this.state[type]['city'] = ""
            this.state[type]['cities'] = this.props.citiesReduc.cities
            this.setState(this.state);
        })
    }

    onChangeCompany = e => {
        var su = { ...this.state.su }
        su[e.target.name] = e.target.value
        this.setState({ su, loading : false });
    };

    onChangeOrigin = e => {
        var or = { ...this.state.or }
        or[e.target.name] = e.target.value
        this.setState({ or, loading : false });
    };

    onChangeDestination = e => {
        var de = { ...this.state.de }
        de[e.target.name] = e.target.value
        this.setState({ de, loading : false });
    };

    onChangeDensity = (e, index) => {
        // e.target.value = densityMask(e.target.value)
        if (e.target.value[0] === ","){
            this.setValidated(false);
        }
        var sps = this.state.sps
        var spsIndexed = sps[index]
        var sp = { ...spsIndexed['sp'] }
        var pr = {...spsIndexed['pr']}
        // sp['kilos'] = ""
        // sp['liters'] = ""
        pr[e.target.name] = e.target.value
        sps[index]['pr'] = pr
        sps[index]['sp'] = sp
        if (sp['isLiters'] === null || sp['isLiters'] === "" || sp['isLiters'] === undefined){
            if (sp['liters']){
                sp['isLiters'] = true
            }
            if (sp['kilos']){
                sp['isLiters'] = false
            }
        }
        this.setState({ sps }, () => {
            if (sp['kilos'] && !sp['isLiters']){
                var kilos = sp['kilos']
                this.onChangeKilos({target:{value:kilos}}, index)
            }else if (sp['liters'] && sp['isLiters']){
                var liters = sp['liters']
                this.onChangeLiters({target:{value:liters}}, index)
            }
        })
    }

    onChangeLiters = (e, index) => {
        var kilos = onChangeLiters(e, this.state.sps[index].pr.density, this)
        this.onChangeProductForKilosLiters(e, index, 'sp', "kilos", kilos)
    }

    onChangeKilos = (e, index) => {
        var liters = onChangeKilos(e, this.state.sps[index].pr.density, this)
        this.onChangeProductForKilosLiters(e, index, 'sp', "liters", liters)
    }

    onChangeProductForKilosLiters = (e, index, type1 = 'sp', name = null, value = null, type2 = null) => {
        var sps = this.state.sps
        var spsIndexed = sps[index]
        var obj = { ...spsIndexed[type1] }
        // obj[e.target.name] = quantidadeMask(e.target.value)
        obj[e.target.name] = e.target.value
        sps[index][type1] = obj
        if (!type2) {
            type2 = type1
        }
        var obj2 = { ...spsIndexed[type2] }
        if (name) {
            obj2[name] = convertDecimalPlaces(value)
            if (name === 'liters' || name === 'kilos') {
                if (value) {
                    obj2['isLiters'] = name !== 'liters'
                }
                else {
                    obj2['isLiters'] = ""
                }
            }
            sps[index][type2] = obj2
        }
        this.setState({ sps });
    }

    onChangeStockedProduct(e, index, type='pr'){
        const { products } = this.props.productsReduc
        var product = products.find(p => p.id === e.target.value)
        var sps = this.state.sps
        var spsIndexed = sps[index]
        var pr = { ...spsIndexed[type] }
        var sp = { ...spsIndexed['sp'] }
        pr[e.target.name] = e.target.value
        pr['density'] = product ? product.density : ''

        sp['liters'] = ''
        sp['kilos'] = ''
        sps[index][type] = pr
        sps[index]['sp'] = sp
        this.setState({ sps })
    }

    onChangeProduct = (e, index, type = 'pr', name = null, value = null) => {
        var sps = this.state.sps
        var spsIndexed = sps[index]
        var obj = { ...spsIndexed[type] }
        if (name) {
            obj[name] = value
        } else {
            obj[e.target.name] = e.target.value
        }
        sps[index][type] = obj
        this.setState({ sps })
    }

    onChangeNCMGeneral = (e, index) => {
        this.onChangeProduct(e, index)
        // this.setState({ loading: true })
        // this.props.getNCMSynonymsByNCMGeneral(e.target.value).then(() => {
        //     this.setState({ loading: false })

        //     const { ncmsynonyms } = this.props.ncmsynonymsReduc
        //     var ncmsynonyms_options = ncmsynonyms.map(c => {
        //         return <option key={c.id} value={c.id}>{c.name}</option>
        //     })
        //     this.onChangeProduct(e, index, 'pr', 'ncm_synonym', "")
        //     this.onChangeProduct(e, index, 'ncmsynonyms', 'options', ncmsynonyms_options)
        // })
    }

    onSaveClick = (event) => {
        var nfProduct = { ...this.state.nfrepo }
        nfProduct.cnpj_customer = onlyNumber(nfProduct.cnpj_customer)
        nfProduct.transporter_type = this.state.transporter_type

        if (this.state.nfrepo.type !== 'FIX'){
            let supplier = { ...this.state.su };
            supplier.cities = null
            supplier.cnpj = onlyNumber(supplier.cnpj)
            supplier.cep = onlyNumber(supplier.cep)
            supplier.number = onlyNumber(supplier.number)
            supplier.main_phone = onlyNumber(supplier.main_phone)
            supplier.secondary_phone = onlyNumber(supplier.secondary_phone)
            supplier = fixBlankProperties(supplier)
            if (this.state.nfrepo.type === 'EST') {
                nfProduct.supplier = supplier
            }
            else {
                nfProduct.acquirer = supplier
            }
            if (this.state.require_transporter) {
                let origin = { ...this.state.or };
                origin.cities = null
                origin.cnpj = onlyNumber(origin.cnpj)
                origin.cep = onlyNumber(origin.cep)
                origin.number = onlyNumber(origin.number)
                origin.main_phone = onlyNumber(origin.main_phone)
                origin.secondary_phone = onlyNumber(origin.secondary_phone)
                origin = fixBlankProperties(origin)
                nfProduct.origin = origin
            }

            if (this.state.require_destination) {
                let destination = { ...this.state.de };
                destination.cities = null
                destination.cnpj = onlyNumber(destination.cnpj)
                destination.cep = onlyNumber(destination.cep)
                destination.number = onlyNumber(destination.number)
                destination.main_phone = onlyNumber(destination.main_phone)
                destination.secondary_phone = onlyNumber(destination.secondary_phone)
                destination = fixBlankProperties(destination)
                nfProduct.destination = destination
            }
        }

        nfProduct.products = []

        this.state.sps.map((item, index) => {
            var p = {}
            if (this.state.nfrepo.type !== 'OUT'){
                p.ncm_general = item.pr.ncm_general
                p.commercial_name = item.pr.commercial_name
                p.concentration = item.pr.concentration
                p.density = item.pr.density.replace(',', '.')
            }
            p.id = item.pr.id
            p.quantity = item.sp.quantity
            p.liters = item.sp.liters.replace(',', '.')
            p.kilos = item.sp.kilos.replace(',', '.')
            p.isLiters = item.sp.isLiters
            p = fixBlankProperties(p)
            if (p.isLiters === null){
                p.isLiters = true
            }
            nfProduct.products.push(p)
        })

        // if (stock.contract_date)
        //     stock.contract_date = new Date(stock.contract_date).toISOString().slice(0, 10)

        nfProduct = fixBlankProperties(nfProduct)

        if (true) {
            this.setState({ loading: true })
            this.props.addStockInBatch(nfProduct).then(() => {
                this.setState({ loading: false })
                history.back()
            }
            ).catch(error => {
                    this.setState({ loading: false })
            })
        }
        else {
            this.setState({ loading: true })
            this.props.updateStock(this.state.co.id, stock).then(() => {
                this.setState({ loading: false })
                // this.props.history.push('/manage/stocks');
                history.back()
            }
            ).catch(error => {
                this.setState({ loading: false })
            }
            )
        }
    };

    render() {
        this.state.su.cnpj = cnpjMask(this.state.su.cnpj)
        this.state.nfrepo.cnpj_customer = cnpjMask(this.state.nfrepo.cnpj_customer)

        const { ncmgenerals } = this.props.ncmgeneralsReduc

        let ncmgenerals_options = ncmgenerals.map(c => {
            return <option key={c.id} value={c.id}>{ c.name + ' ' + c.code }</option>
        })

        const { ufs } = this.props.ufsReduc

        let uf_options = ufs.map(c => {
            return <option key={c.id} value={c.id}>{c.abreviation}</option>
        })

        let city_options_su = this.state.su.cities.map(c => {
            return <option key={c.id} value={c.id}>{c.city}</option>
        })

        let city_options_or = this.state.or.cities.map(c => {
            return <option key={c.id} value={c.id}>{c.city}</option>
        })

        let city_options_de = this.state.de.cities.map(c => {
            return <option key={c.id} value={c.id}>{c.city}</option>
        })
        
        const { products } = this.props.productsReduc
        let products_options = products.map(c => {          
            var commercial_name = c.commercial_name ? "- " + c.commercial_name.toUpperCase() : ""  
            return <option key={c.id} value={c.id}>{ `${c.ncm_general_code} ${c.ncm_general_name} ${commercial_name} | Conc ${c.concentration}% - Dens ${c.density }` }</option>
        })
        let i = 0
        return (
            <>
                <Loader loading={this.state.loading} />
                
                {this.state.alert &&
                    <Panel header="Alertas">
                        <Message severity="warn" text={this.state.alert} />
                    </Panel>
                }
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">
                                    {this.state.nfrepo.type === 'EST' &&
                                        <>
                                            Assistente de Importação de Produtos
                                        </>
                                    }
                                    {this.state.nfrepo.type === 'OUT' &&
                                        <>
                                            Assistente de Saída
                                        </>
                                    }
                                    {this.state.nfrepo.type === 'FIX' &&
                                        <>
                                            Assistente de Retificação de Produtos
                                        </>
                                    }
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form id="AddStock" noValidate validated={this.state.validated}>

                                    {this.state.nfrepo.type !== 'FIX' &&
                                    <>
                                    <Row>
                                        <h5 className="text-center text-secondary font-weight-bold "><b>Confirmação da Nota Fiscal</b></h5> <hr></hr>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="nf">
                                            <Form.Label className="font-weight-bold">N° Nota fiscal</Form.Label>
                                            <Form.Control required type="text" name="nf" placeholder="número" value={this.state.nfrepo.nf}
                                                onChange={this.onChangeNF} maxLength={10} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="nf">
                                            <Form.Label className="font-weight-bold">Data emissão</Form.Label>
                                            <Form.Control required type="date" name="issued_at"
                                                value={DateTime.fromISO(this.state.nfrepo.issued_at, {setZone: true}).toFormat('yyyy-MM-dd')}
                                                onChange={this.onChangeNF} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="cnpj_customer">
                                            <Form.Label className="font-weight-bold">
                                                {(this.state.nfrepo.type === 'EST' ||this.state.nfrepo.type === 'FIX') &&
                                                    <>
                                                        CNPJ Destinatário
                                                    </>
                                                }
                                                {this.state.nfrepo.type === 'OUT' &&
                                                    <>
                                                        CNPJ Fornecedor
                                                    </>
                                                }    </Form.Label>
                                            <Form.Control required type="text" name="cnpj_customer" placeholder="CNPJ" value={this.state.nfrepo.cnpj_customer}
                                                onChange={this.onChangeCnpj} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row>
                                        <h5 className="text-center text-secondary font-weight-bold ">
                                            {this.state.nfrepo.type === 'EST' &&
                                                <>
                                                <b>Confirmação do Fornecedor </b>
                                                {/* <Button className="btn btn-primary" title='Editar fornecedor'>
                                                    <PencilFill color="royalblue" />
                                                </Button> */}
                                                </>
                                            }
                                            {this.state.nfrepo.type === 'OUT' &&
                                                <b>Confirmação do Adquirente</b>
                                            }
                                        </h5> <hr></hr>
                                    </Row>
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="4" controlId="cnpj">
                                            <Form.Label className="font-weight-bold">CNPJ</Form.Label>
                                            <Form.Control required type="text" name="cnpj" placeholder="CNPJ" value={this.state.su.cnpj}
                                                onChange={this.onChangeCnpjForCompany} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="corporate_name">
                                            <Form.Label className="font-weight-bold">Razão social</Form.Label>
                                            <Form.Control required type="text" name="corporate_name" placeholder="Razão Social" value={this.state.su.corporate_name}
                                                onChange={this.onChangeCompany} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="fantasy_name">
                                            <Form.Label className="font-weight-bold">Nome fantasia</Form.Label>
                                            <Form.Control required type="text" name="fantasy_name" placeholder="Nome Fantasia" value={this.state.su.fantasy_name}
                                                onChange={this.onChangeCompany} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="address">
                                            <Form.Label className="font-weight-bold">Endereço</Form.Label>
                                            <Form.Control required type="text" name="address" placeholder="Endereço" value={this.state.su.address}
                                                onChange={this.onChangeCompany} maxLength={500} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="number">
                                            <Form.Label className="font-weight-bold">Número</Form.Label>
                                            <Form.Control type="number" name="number" placeholder="Número" value={this.state.su.number}
                                                onChange={this.onChangeCompany} maxLength={5} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="cep">
                                            <Form.Label className="font-weight-bold">CEP</Form.Label>
                                            <Form.Control type="text" name="cep" placeholder="CEP" value={this.state.su.cep}
                                                onChange={this.onChangeCompany} as={IMaskInput} mask="00000-000" maxLength={10} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="neighborhood">
                                            <Form.Label className="font-weight-bold">Bairro</Form.Label>
                                            <Form.Control type="text" name="neighborhood" placeholder="Bairro" value={this.state.su.neighborhood}
                                                onChange={this.onChangeCompany} maxLength={50} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="state">
                                            <Form.Label className="font-weight-bold">Estado</Form.Label>
                                            <Form.Control required as="select" name="state" placeholder="Estado" value={this.state.su.state}
                                                onChange={e => this.onChangeUf(e, this.onChangeCompany, 'su')} >
                                                <option value="">--- SELECIONE ---</option>
                                                {uf_options}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="city">
                                            <Form.Label className="font-weight-bold">Cidade</Form.Label>
                                            <Form.Control required as="select" name="city" placeholder="Cidade" value={this.state.su.city}
                                                onChange={this.onChangeCompany} >
                                                <option value="">--- SELECIONE ---</option>
                                                {city_options_su}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="main_phone">
                                            <ControlPhone label="Telefone principal" name="main_phone" value={this.state.su.main_phone}
                                                onChangePhone={this.onChangeCompany} />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="secondary_phone">
                                            <ControlPhone label="Telefone opcional" required={false} name="secondary_phone" value={this.state.su.secondary_phone} 
                                                onChangePhone={this.onChangeCompany} />
                                            
                                        </Form.Group>
                                    </Row>

                                    <Row>
                                        <h5 className="text-center text-secondary font-weight-bold"><b>Confirmação do Transportador </b></h5> <hr></hr>
                                    </Row>
                                    <Row>
                                        {/* radio button, selecionar responsável pelo transporte: Fornecedor, Adquirinte ou Transportadora */}
                                        <Form.Group as={Col} md="4" controlId="">
                                            <Form.Label className="font-weight-bold">Responsável pelo transporte</Form.Label>
                                            <Form.Control required as="select" name="transporter_type" placeholder="Responsável pelo transporte" value={this.state.transporter_type}
                                                onChange={this.changeTransporter} >
                                                <option value="">--- SELECIONE ---</option>
                                                <option value="F">Fornecedor</option>
                                                <option value="A">Adquirente</option>
                                                <option value="T">Terceirizada</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>
                                    {this.state.require_transporter && <>
                                    
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="4" controlId="cnpj">
                                            <Form.Label className="font-weight-bold">CNPJ</Form.Label>
                                            <Form.Control required type="text" name="cnpj" placeholder="CNPJ" value={this.state.or.cnpj}
                                                onChange={this.onChangeCnpjForOrigin} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="corporate_name">
                                            <Form.Label className="font-weight-bold">Razão social</Form.Label>
                                            <Form.Control required type="text" name="corporate_name" placeholder="Razão Social" value={this.state.or.corporate_name}
                                                onChange={this.onChangeOrigin} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="fantasy_name">
                                            <Form.Label className="font-weight-bold">Nome fantasia</Form.Label>
                                            <Form.Control required type="text" name="fantasy_name" placeholder="Nome Fantasia" value={this.state.or.fantasy_name}
                                                onChange={this.onChangeOrigin} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="address">
                                            <Form.Label className="font-weight-bold">Endereço</Form.Label>
                                            <Form.Control required type="text" name="address" placeholder="Endereço" value={this.state.or.address}
                                                onChange={this.onChangeOrigin} maxLength={500} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="number">
                                            <Form.Label className="font-weight-bold">Número</Form.Label>
                                            <Form.Control type="number" name="number" placeholder="Número" value={this.state.or.number}
                                                onChange={this.onChangeOrigin} maxLength={5}  />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="cep">
                                            <Form.Label className="font-weight-bold">CEP</Form.Label>
                                            <Form.Control type="text" name="cep" placeholder="CEP" value={this.state.or.cep}
                                                onChange={this.onChangeOrigin} as={IMaskInput} mask="00000-000" maxLength={10} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="neighborhood">
                                            <Form.Label className="font-weight-bold">Bairro</Form.Label>
                                            <Form.Control type="text" name="neighborhood" placeholder="Bairro" value={this.state.or.neighborhood}
                                                onChange={this.onChangeOrigin} maxLength={50} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="state">
                                            <Form.Label className="font-weight-bold">Estado</Form.Label>
                                            <Form.Control required as="select" name="state" placeholder="Estado" value={this.state.or.state}
                                                onChange={e => this.onChangeUf(e, this.onChangeOrigin, 'or')} >
                                                <option value="">--- SELECIONE ---</option>
                                                {uf_options}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="city">
                                            <Form.Label className="font-weight-bold">Cidade</Form.Label>
                                            <Form.Control required as="select" name="city" placeholder="Cidade" value={this.state.or.city}
                                                onChange={this.onChangeOrigin} >
                                                <option value="">--- SELECIONE ---</option>
                                                {city_options_or}
                                            </Form.Control>
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="main_phone">
                                            <ControlPhone label="Telefone principal" name="main_phone" value={this.state.or.main_phone}
                                                onChangePhone={this.onChangeOrigin} />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="secondary_phone">
                                            <ControlPhone label="Telefone opcional" required={false} name="secondary_phone" value={this.state.or.secondary_phone}
                                                onChangePhone={this.onChangeOrigin} />
                                        </Form.Group>
                                    </Row> </>}

                                    {!this.state.require_destination && <><Row>
                                        <Button className="btn btn-light active" variant="info" type="button" onClick={this.changeDestination} >Adicionar Destino de Armazenamento</Button>
                                    </Row><br /></>}

                                    {this.state.require_destination && <><Row>
                                        <h5 className="text-center text-secondary font-weight-bold "><b>Confirmação do Destino de Armazenamento</b></h5>
                                        <hr></hr>
                                        <Button variant="danger" className="btn btn-primary" onClick={this.changeDestination}>
                                            Remover Destino de Armazenamento? <TrashFill color="red" />
                                        </Button>
                                    </Row>

                                        <Row className="mb-3">

                                            <Form.Group as={Col} md="4" controlId="cnpj">
                                                <Form.Label className="font-weight-bold">CNPJ</Form.Label>
                                                <Form.Control required type="text" name="cnpj" placeholder="CNPJ" value={this.state.de.cnpj}
                                                    onChange={this.onChangeCnpjForDestination} maxLength={200} />
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="4" controlId="corporate_name">
                                                <Form.Label className="font-weight-bold">Razão social</Form.Label>
                                                <Form.Control required type="text" name="corporate_name" placeholder="Razão Social" value={this.state.de.corporate_name}
                                                    onChange={this.onChangeDestination} maxLength={200} />
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="4" controlId="fantasy_name">
                                                <Form.Label className="font-weight-bold">Nome fantasia</Form.Label>
                                                <Form.Control required type="text" name="fantasy_name" placeholder="Nome Fantasia" value={this.state.de.fantasy_name}
                                                    onChange={this.onChangeDestination} maxLength={200} />
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="address">
                                                <Form.Label className="font-weight-bold">Endereço</Form.Label>
                                                <Form.Control required type="text" name="address" placeholder="Endereço" value={this.state.de.address}
                                                    onChange={this.onChangeDestination} maxLength={500} />
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="3" controlId="number">
                                                <Form.Label className="font-weight-bold">Número</Form.Label>
                                                <Form.Control type="number" name="number" placeholder="Número" value={this.state.de.number}
                                                    onChange={this.onChangeDestination} maxLength={5} />
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="3" controlId="cep">
                                                <Form.Label className="font-weight-bold">CEP</Form.Label>
                                                <Form.Control type="text" name="cep" placeholder="CEP" value={this.state.de.cep}
                                                    onChange={this.onChangeDestination} as={IMaskInput} mask="00000-000" maxLength={10} />
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="3" controlId="neighborhood">
                                                <Form.Label className="font-weight-bold">Bairro</Form.Label>
                                                <Form.Control type="text" name="neighborhood" placeholder="Bairro" value={this.state.de.neighborhood}
                                                    onChange={this.onChangeDestination} maxLength={50} />
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="3" controlId="state">
                                                <Form.Label className="font-weight-bold">Estado</Form.Label>
                                                <Form.Control required as="select" name="state" placeholder="Estado" value={this.state.de.state}
                                                    onChange={e => this.onChangeUf(e, this.onChangeDestination, 'de')} >
                                                    <option value="">--- SELECIONE ---</option>
                                                    {uf_options}
                                                </Form.Control>
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="3" controlId="city">
                                                <Form.Label className="font-weight-bold">Cidade</Form.Label>
                                                <Form.Control required as="select" name="city" placeholder="Cidade" value={this.state.de.city}
                                                    onChange={this.onChangeDestination} >
                                                    <option value="">--- SELECIONE ---</option>
                                                    {city_options_de}
                                                </Form.Control>
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" controlId="main_phone">
                                                <ControlPhone label="Telefone principal" name="main_phone" value={this.state.de.main_phone}
                                                    onChangePhone={this.onChangeDestination} />
                                            </Form.Group>

                                            <Form.Group as={Col} md="4" controlId="secondary_phone">
                                                <ControlPhone label="Telefone opcional" required={false} name="secondary_phone" value={this.state.de.secondary_phone}
                                                    onChangePhone={this.onChangeDestination} />
                                            </Form.Group>
                                        </Row></>}
                                        </>}
                                    <Row>
                                        <h5 className="text-center text-secondary font-weight-bold"><b>Confirmação dos Produtos</b></h5> <hr></hr>
                                    </Row>
                                    {this.state.sps.map((row, index) => {
                                        var form1 = <>
                                                        <Form.Group key={i++} as={Col} md="6" controlId="id">
                                                        <Form.Label key={i++} className="font-weight-bold" >Produto</Form.Label>
                                                        <Form.Control key={i++} 
                                                            as="select" required aria-label="Produto" name="id" onChange={e => this.onChangeStockedProduct(e, index)} value={row.pr.id}>
                                                            <option value="">--- SELECIONE ---</option>
                                                            {products_options}
                                                        </Form.Control>
                                                        <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </>

                                        var form2 = <>
                                                    <>
                                                    <Col md ={"4"} key={i++}>
                                                        <AutoCompleteNCM
                                                            key={i++}
                                                            name="ncm_general"
                                                            required={true}
                                                            onChange={(e) => this.onChangeNCMGeneral(e, index)}
                                                            value={row.pr.ncm_general} />
                                                    </Col>
                                                    <Form.Group key={i++} as={Col} md="3" controlId="commercial_name">
                                                        <Form.Label key={i++} className="font-weight-bold">Nome Comercial</Form.Label>
                                                        <Form.Control key={i++} type='text' name="commercial_name" placeholder="Nome"
                                                            onChange={e => this.onChangeProduct(e, index)}
                                                            value={row.pr.commercial_name}
                                                            maxLength={250} />
                                                        <Form.Control.Feedback key={i++}>Ok</Form.Control.Feedback>
                                                        <Form.Control.Feedback key={i++} type="invalid">
                                                            Campo obrigatório.
                                                        </Form.Control.Feedback>
                                                    </Form.Group></>                                                        

                                                    <Form.Group key={i++} as={Col} md="1" controlId="concentration">
                                                        <ControlConcentration key={i++} value={row.pr.concentration} min={1} max={100}
                                                            onChangeConcentration={e => this.onChangeProduct(e, index)} />
                                                    </Form.Group>

                                                    <Form.Group key={i++} as={Col} md="1" controlId="density">
                                                        <ControlDensity key={i++} onChangeDensity={e => this.onChangeDensity(e, index)} 
                                                            value={row.pr.density} />
                                                    </Form.Group>
                                                </>
                                        var righform = this.state.nfrepo.type === 'OUT' || this.state.nfrepo.type === 'FIX' ? form1 : form2
                                        return (
                                            <Row key={i++} className="mb-3">
                                                {righform}
                                                <Form.Group key={i++} as={Col} md="1" controlId="kilos">
                                                    <ControlKilosLiters key={i++} onChangeMU={e => this.onChangeKilos(e, index)}  
                                                    req={!row.sp.liters && !row.sp.liters} dis={row.sp.isLiters && row.sp.liters !== ""}
                                                    value={row.sp.kilos} label="Kilos" name="kilos"/>
                                                </Form.Group>

                                                <Form.Group key={i++} as={Col} md="1" controlId="liters">
                                                    <ControlKilosLiters key={i++} onChangeMU={e => this.onChangeLiters(e, index)}  
                                                        req={!row.sp.liters && !row.sp.liters} dis={!row.sp.isLiters && row.sp.kilos !== ""} 
                                                        value={row.sp.liters} label="Litros" name="liters"/>
                                                </Form.Group>

                                                <Col key={i++} md="1">
                                                    <br />
                                                    <Button variant="danger" className="btn btn-primary" onClick={() => this.onRemoveSpFromReview(index)}>
                                                        <TrashFill color="red" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            )
                                    }
                                    )}

                                    <Row>
                                        <Button className="btn btn-light active" variant="success" type="button" onClick={this.addSpToReview} >+1 Produto</Button>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Button className="btn btn btn-primary active btn-floating position-sticky" style={{ bottom: '20px', right: '20px' }} type="button" onClick={this.handleSubmit} >
                            Salvar
                        </Button>{' '}
                        <Button onClick={() => history.back()} type="button" className="btn btn-danger active btn-floating position-sticky" style={{ bottom: '20px', right: '20px' }}>
                            Cancelar
                        </Button>
                    </Col>
                </Row>
               
            </>
        )
    }
}

AddStock.propTypes = {
    addStock: PropTypes.func.isRequired,
    updateStock: PropTypes.func.isRequired,
    getStock: PropTypes.func.isRequired,
    stocksReduc: PropTypes.object.isRequired,
    ncmgeneralsReduc: PropTypes.object.isRequired,
    ncmsynonymsReduc: PropTypes.object.isRequired,
    addProduct: PropTypes.func.isRequired,
    addStockInBatch: PropTypes.func.isRequired,
    updateProduct: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired,
    getNCMSynonymsByNCMGeneral: PropTypes.func.isRequired,
    getUfs: PropTypes.func.isRequired,
    getCitiesByUf: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    commercialproductsReduc: PropTypes.object.isRequired,
    productsReduc: PropTypes.object.isRequired,
    suppliersReduc: PropTypes.object.isRequired,
    originsReduc: PropTypes.object.isRequired,
    acquirersReduc: PropTypes.object.isRequired,
    destinationsReduc: PropTypes.object.isRequired,
    getSuppliers: PropTypes.func.isRequired,
    getOrigins: PropTypes.func.isRequired,
    getAcquirers: PropTypes.func.isRequired,
    getDestinations: PropTypes.func.isRequired,
    getNf: PropTypes.func.isRequired,
    nfsReduc: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    stocksReduc: state.stocksReduc,
    ncmgeneralsReduc: state.ncmgeneralsReduc,
    ncmsynonymsReduc: state.ncmsynonymsReduc,
    ufsReduc: state.ufsReduc,
    citiesReduc: state.citiesReduc,
    commercialproductsReduc: state.commercialproductsReduc,
    productsReduc: state.productsReduc,
    suppliersReduc: state.suppliersReduc,
    originsReduc: state.originsReduc,
    acquirersReduc: state.acquirersReduc,
    destinationsReduc: state.destinationsReduc,
    nfsReduc: state.nfsReduc
});


export default connect(mapStateToProps, {
    addStock, updateStock, getStock, addProduct, updateProduct, getProduct, addStockInBatch
    , getNCMSynonymsByNCMGeneral, getUfs, getCitiesByUf, getProducts,
    getSuppliers, getOrigins, getAcquirers, getDestinations, getNf
})(withRouter(AddStock));