import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import AddMovement from "../components/movement/AddMovement";
import MovementList from "../components/movement/MovementList"
import { getPersons } from "../components/persons/PersonsActions";
import { getProducts } from "../components/product/ProductActions";
import { toastOnError } from "../utils/Utils";

class Movement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listing: true,
        };
    }
    handleClick = (destination) => {
        const { persons } = this.props.personsReduc;
        var cnpj_empresa = persons.filter( p => p.email == this.props.auth.user.email).map(
            (us) => {
               return us.company_cnpj 
            })
        this.props.getProducts({is_message_to_user:false, customer:cnpj_empresa[0]}).then(() =>{
            const { products } = this.props.productsReduc
            if (products.length > 0){
                this.props.history.push(destination)
            }
            else{
                toastOnError("Não há estoque disponível")
            }
        })
    };
    handleNovaUtilizacaoClick = () => {
        this.handleClick("/manage/movements/edit");
      };
      
      handleGerarSaidaClick = () => {
        this.handleClick("/manage/stocks/editxml?stock=OUT");
      };
    
      


    render() {
        const { listing } = this.state;
        return (
            <div>
            {/* <Container> */}
                <MovementList />

                <Button
                    className="btn btn-primary position-sticky fixed-button"
                    onClick={this.handleNovaUtilizacaoClick}
                >
                    Nova Utilização
                </Button>
                {' '}
                <Link className="btn btn-warning position-sticky fixed-button" to="/manage/stocks/edit">
                    Importar XML
                </Link>
                {' '}
                <Button
                    className="btn btn-success position-sticky fixed-button"
                    onClick={this.handleGerarSaidaClick}
                >
                    Gerar Saída de NF
                </Button>
                
                {/* <Link
                    className="btn btn-warning position-sticky fixed-button"
                    to="/manage/stocks/edit"
                >
                    Importar Nota Fiscal
                </Link> */}
                

            {/* </Container> */}
        </div>
        );
    }
}

class CreateMovement extends Component {
    constructor() {
        super();
    }

    render() {

        return (
            <div>
                <Container>
                    <AddMovement />
                </Container>
            </div>
        );
    }
}

Movement.propTypes = {
  getPersons: PropTypes.func.isRequired,
  personsReduc: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getProducts: PropTypes.func.isRequired,
  productsReduc: PropTypes.object.isRequired,

}
const mapStateToProps = state => ({
    auth: state.auth,
    personsReduc: state.personsReduc,
    productsReduc: state.productsReduc,
});

const ListMV = connect(mapStateToProps,  { getPersons,getProducts
})(withRouter(Movement))

const CreateMV = connect(mapStateToProps, {
})(withRouter(CreateMovement))

export { ListMV, CreateMV }