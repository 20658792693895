import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_FPFILES, GET_FPFILE, ADD_FPFILE, DELETE_FPFILE, UPDATE_FPFILE, MODAL_ADD_FPFILE} from "./FpFileTypes";
import { toast } from "react-toastify";

export const getFpFiles = (options={supplier:null} ) => dispatch => {

  var url = "/api/v1/fpfiles/"
  var complement = []
  if (options.supplier){
    complement.push(`supplier=${options.supplier}`)
  }


  if (options.start_at){
    complement.push(`start_at=${options.start_at}`)
  }

  if (options.end_at){
    complement.push(`end_at=${options.end_at}`)
  }

  if (complement.length > 0){
    url += "?" + complement.join("&")
  }
  return axios
    .get(url)
    .then(response => {      
      dispatch({
        type: GET_FPFILES,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const addFpFile = company => dispatch => {
  return axios
    .post("/api/v1/fpfiles/", company)
    .then(response => {
      dispatch({
        type: ADD_FPFILE,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteFpFile = id => dispatch => {
  axios
    .delete(`/api/v1/fpfiles/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_FPFILE,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getFpFile = id => dispatch => {
  return axios
    .get(`/api/v1/fpfiles/${id}/`)
    .then(response => {
      dispatch({
        type: GET_FPFILE,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateFpFile = (id, company) => dispatch => {
  return axios
    .patch(`/api/v1/fpfiles/${id}/`, company)
    .then(response => {
      dispatch({
        type: UPDATE_FPFILE,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const modalAddFpFile = (fpFile) => dispatch => {
  dispatch({
    type: MODAL_ADD_FPFILE,
    payload: fpFile
  })
}