import { DateTime } from 'luxon';

export const formatDate = (stringValue, localization = 'pt-BR') => {
    return new Date(stringValue).toLocaleDateString(localization, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
  };

  export const formatDateAndHours = (stringValue, localization = 'pt-BR') => {
    return new Date(stringValue).toLocaleDateString(localization, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour:'numeric',
        minute:'numeric',
        second:'numeric'
    });
  };

export const formaDateSimple = (stringValue) => {
    return new Date(stringValue).toLocaleDateString()
}

export const dateFromIso = (dateobj, localization = null) => {
    return DateTime.fromISO(dateobj, {setZone: true}).toLocaleString(DateTime.DATE_SHORT, {locale: localization})
}

// localization = 'pt-BR'
export const datetimeOrDateFromIso = (dateobj, localization = null) => {
    //check if date is midnight
    let date = DateTime.fromISO(dateobj, {setZone: true})
    if (date.hour === 0 && date.minute === 0 && date.second === 0) {
        return date.toLocaleString(DateTime.DATE_SHORT, { locale: localization})
    } else {
        return date.toLocaleString(DateTime.DATETIME_SHORT, { locale: localization})
    }
}

export const datetimeFromIso = (dateobj) => {
    return DateTime.fromISO(dateobj, {setZone: true}).toLocaleString(DateTime.DATETIME_SHORT)
}
