import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getNfs } from "./NfActions";
import { IMaskInput } from "react-imask";
import Nf from "./Nf"
import { setMask, cnpjMask, cnpjValidation, onlyNumber } from "../../utils/mask"
import { formatDate, formatDateAndHours, formaDateSimple, dateFromIso } from "../../utils/Data";
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import { header } from "../../utils/Datagrid";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Loader from "components/utils/Loader"
import { DateTime } from 'luxon';

import {
  Card,
  Form,
  Row,
  Col,
} from "react-bootstrap";

class NfList extends Component {
  constructor(props) {
    super(props);
    this.dt = React.createRef();
    this.state = {
      co: { search: '0', order_by: '-issued_at', confirmed: this.props.confirmed },
      filters: {
        number: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        issued_at: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        enabled_virtual: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      loading: false
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.props.getNfs(
      {
        enabled: this.state.co.search,
        order_by: this.state.co.order_by,
        confirmed: this.state.co.confirmed
      }
    ).then(() => {
    })
      .catch((error) => { })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  cols = [
    { field: 'number', header: 'Número' },
    { field: 'issued_at', header: 'Data de referência' },
    { field: 'enabled_virtual', header: 'Ativo' }
  ];

  exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));

  onChange = e => {
    var co = { ...this.state.co }
    co[e.target.name] = e.target.value
    this.setState({ co, loading: true })
    this.props.getNfs(
      {
        enabled: co.search,
        number_contains: co.number,
        start_at: co.start_at,
        end_at: co.end_at,
        order_by: co.order_by
      }

    ).then(() => {
      this.setState({ loading: false })
    })
  };

  dateBodyTemplate = (rowData) => {
    return formatDate(rowData.created_at);
  };

  alocBodyTemplate = (rowData) => {
    return <Nf key={rowData.id} nf={rowData} />
  }
  sortDate = (event) => {
  const { data, field, order } = event;

  return data.sort((a, b) => {
    const dateA = DateTime.fromFormat(a[field], 'dd/MM/yyyy');
    const dateB = DateTime.fromFormat(b[field], 'dd/MM/yyyy');

    if (!dateA.isValid || !dateB.isValid) {
      return 0;
    }

    if (order === 1) {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });
};
  render() {
    const { nfs } = this.props.nfsReduc;

    let itens = nfs.map(obj => {
      var aux = { ...obj }
      aux.issued_at = formatDate(obj.issued_at)
      aux.enabled_virtual = obj.enabled ? "Sim" : "Não"
      return aux
    });

    return (
      <>
        <Loader loading={this.state.loading} />
        {((this.state.co.confirmed && this.state.co.confirmed == '0' && itens.length > 0) || !this.state.co.confirmed) &&
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Gerenciar Notas Fiscais {this.state.co.confirmed == '0' ? 'Não Confirmadas' : ''} </Card.Title>
                  <p className="card-category">
                    Notas fiscais {this.state.co.confirmed == '0' ? 'não confirmadas' : ''}
                  </p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <div className="card">
                    <Tooltip target=".export-buttons>button" position="bottom" />
                    <DataTable ref={this.dt} value={itens} removableSort header={(<div>
                      <Row className="mb-3"></Row>
                      <Row>
                        <Col md="10">
                          <Row >
                            <Form.Group as={Col} md="4" controlId="search">
                              <Form.Label className="font-weight-bold" >N° Nota fiscal</Form.Label>
                              <Form.Control
                                type="text" required name="number" value={this.state.co.number}
                                onChange={this.onChange} maxLength={100} >
                              </Form.Control >
                              <Form.Control.Feedback>Ok</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                Campo obrigatório.
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="nf">
                              <Form.Label className="font-weight-bold">Data de emissão inicial</Form.Label>
                              <Form.Control required type="date" name="start_at"
                                value={DateTime.fromISO(this.state.co.start_at, { setZone: true }).toFormat('yyyy-MM-dd')}
                                onChange={this.onChange} maxLength={200} />
                              <Form.Control.Feedback>Ok</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                Campo obrigatório.
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="4" controlId="nf">
                              <Form.Label className="font-weight-bold">Data de emissão final</Form.Label>
                              <Form.Control required type="date" name="end_at"
                                value={DateTime.fromISO(this.state.co.end_at, { setZone: true }).toFormat('yyyy-MM-dd')}
                                onChange={this.onChange} maxLength={200} />
                              <Form.Control.Feedback>Ok</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                Campo obrigatório.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                        </Col>
                        <Col>
                          {header(nfs, "Gestoras", this.dt, this.exportColumns)}
                        </Col>
                      </Row>
                    </div>
                    )}
                      tableStyle={{ minWidth: '50rem' }}
                      paginator rows={5}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      filterDisplay="row"
                      filters={this.state.filters}
                      emptyMessage="Não há notas fiscais"

                    >
                      {this.cols.map((col, index) => (
                        col.field === 'issued_at' ? (
                          <Column
                            key={index}
                            field={col.field}
                            header={col.header}
                            sortable
                            sortFunction={this.sortDate} 
                          />
                        ) : (
                          <Column key={index} field={col.field} header={col.header} sortable />
                        )
                      ))}
                      <Column body={this.alocBodyTemplate}></Column>
                    </DataTable>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>}
      </>
    );
  }
}

NfList.propTypes = {
  getNfs: PropTypes.func.isRequired,
  nfsReduc: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  nfsReduc: state.nfsReduc
});

export default connect(mapStateToProps, {
  getNfs
})(withRouter(NfList));